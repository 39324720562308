<template>
  <!-- Version: 1.7 -->
  <h2>1. Descrierea jocului</h2>
  <p>
    Jocul de pariuri pe fotbal de la Highlight Games este o simulare software unică a unor momente importante din
    meciuri de fotbal selectate aleatoriu pentru a crea oportunități de joc.
  </p>
  <h2>2. Descrierea și durata jocului</h2>
  <p>
    O singură rundă a unui joc de pariuri electronice are o durată cuprinsă între 3 și 5 minute. Aceasta constă în
    anunțul evenimentului, preambulul, afișarea în joc și rezultatul. Afișarea în joc va avea o durată de 1 minut.
  </p>
  <p>
    O selecție de evenimente fotbalistice importante este independentă din punct de vedere statistic, aleatorie și
    imprevizibilă și este generată de Generatorul de numere aleatorii - RNG.
  </p>
  <h2>3. Piețele de pariuri</h2>
  <h3>3.1 Număr exact de goluri</h3>
  <p>
    Pariurile pe Numărul exact de goluri înseamnă că trebuie să se înscrie un număr exact de goluri într-un singur meci.
    Așadar, această variantă de pariere înseamnă că trebuie să preziceți un număr precis de goluri, în ciuda
    rezultatului final al unui întreg meci.
  </p>
  <h3>3.2 Număr exact de goluri înscrise de echipa gazdă</h3>
  <p>Un pariu pe numărul exact de goluri pe care echipa gazdă le va înscrie în meci.</p>
  <h3>3.3 Număr exact de goluri înscrise de echipa oaspete</h3>
  <p>Un pariu pe numărul exact de goluri pe care echipa oaspete le va înscrie în meci.</p>
  <h3>3.4 Ambele echipe marchează</h3>
  <p>
    Pentru a câștiga un pariu Ambele echipe marchează, ambele echipe trebuie să înscrie cel puțin un gol, ceea ce face
    ca orice rezultat de la 1-1 în sus să fie un pariu câștigător. Pariul „Ambele echipe marchează” le oferă pariorilor
    șansa de a profita de astfel de evenimente, pentru a obține cote profitabile atunci când doar una dintre cele două
    echipe a reușit să înscrie.
  </p>
  <h3>3.5 Scor corect</h3>
  <p>
    Pariurile pe Scor corect înseamnă să pariați pe ceea ce credeți dvs. că va fi rezultatul final al unui meci de
    fotbal, cu un maxim de 6 goluri într-un meci, există 28 de rezultate posibile.
  </p>
  <h3>3.6 Șansă dublă</h3>
  <p>
    Un pariu pe Șansă dublă vă permite să acoperiți două dintre cele trei rezultate posibile ale unui meci de fotbal cu
    un singur pariu. Echipa gazdă și remiză - Pariul dvs. este câștigător dacă echipa gazdă câștigă sau remizează
    meciul. Echipa oaspete și remiză - Pariul dvs. este câștigător dacă echipa oaspete câștigă sau remizează meciul.
  </p>
  <h3>3.7 Rezultatul meciului (1X2)</h3>
  <p>
    Un pariu pe victoria echipei gazdă (1), pe victoria echipei oaspete (2) sau pe un meci care se termină cu remiză
    (X).
  </p>
  <h3>3.8 Rezultatul meciului și Ambele echipe marchează (1X2 + Ambele echipe marchează)</h3>
  <p>
    Un pariu pe rezultatul meciului (1 este victoria echipei gazdă, X este remiză, 2 este victoria echipei oaspete) și
    dacă ambele echipe înscriu sau nu.
  </p>
  <ul>
    <li>1 + Gol - Echipa gazdă câștigă și ambele echipe marchează cel puțin un gol [de exemplu, 3-1].</li>
    <li>1 + Fără gol - Echipa gazdă câștigă și este singura echipă care înscrie un gol [de exemplu, 1-0].</li>
    <li>X + Gol - Pariu pe remiză și ambele echipe marchează. [de exemplu, 1 -1, 2-2].</li>
    <li>X + Fără gol - Pariu pe remiză și fără ca echipele să marcheze. [doar 0-0].</li>
    <li>2 + Gol - Echipa oaspete câștigă și ambele echipe marchează cel puțin un gol [de exemplu, 1-2, 1-3].</li>
    <li>2 + Fără gol - Echipa oaspete câștigă și este singura echipă care înscrie goluri [de exemplu, 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Rezultatul meciului și Peste/Sub 1,5 goluri</h3>
  <p>Un pariu pe rezultatul meciului de la 1X2 și pe numărul total de goluri care va fi sub sau peste 1,5 goluri.</p>
  <h3>3.10 Rezultatul meciului și Peste/Sub 2,5 goluri</h3>
  <p>Un pariu pe rezultatul meciului de la 1X2 și pe numărul total de goluri care va fi sub sau peste 2,5 goluri.</p>
  <h3>3.11 Total goluri (între 1 și 2, 1 și 3, 1 și 4, 2 și 3, 2 și 4)</h3>
  <p>
    Un pariu că rezultatul final al meciului va avea între 1 și 2, 1 și 3, 1 și 4, 2 și 3 sau 2 și 4 goluri în total.
    Acesta este un pariu de tip DA sau NU.
  </p>
  <h3>3.12 Peste/Sub 1,5 goluri înscrise de echipa gazdă</h3>
  <p>Un pariu că echipa gazdă va înscrie mai mult sau mai puțin de 1,5 goluri în meci.</p>
  <h3>3.13 Peste/Sub 1,5 goluri înscrise de echipa oaspete</h3>
  <p>Un pariu că echipa oaspete va înscrie mai mult sau mai puțin de 1,5 goluri în meci.</p>
  <h3>3.14 Peste/Sub 1,5, 2,5, 3,5, 4,5 Total goluri</h3>
  <p>Un pariu că numărul total de goluri marcate de ambele echipe va fi sub sau peste 1,5, 2,5, 3,5 sau 4,5 în meci.</p>
</template>
